// 委托单管理页面
import React, { Component } from 'react'
import {Layout, Modal, Radio, message, Tag, Select, Button, Popconfirm} from 'antd'
import { NavLink } from "react-router-dom"
import Pubsub from 'pubsub-js'
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import Conditions from '../../../components/common/conditions/Conditions'
import moment from 'moment'
import {TYPE_NAME} from '../../../components/Const'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import {funTraceInfo} from '../../../services/common'

import {
	getEntrustList,
	allDelTemporaryAlarm,
	cancellationEntrust,
	getEntrustInvalid,
	removeEntrust, removeAlarm
} from '../../../services/verifvication'
import FormsTwo from "../../../components/common/forms/FormsTwo";
const { Option } = Select;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class PointManagement extends Component {

    state = {
		salesmanDates:null,// 业务员
		verifierDates:[],  // 检定员
		// 表格参数
		tableParamenter: {
			isNotInitData:true,
			overflow:true,
			manageName: "数据项管理",
			// 表格行是否可选
            // rowSelection: true,
			//下拉框长度
			selectWidth: '6.5vw',
			// 表头：变量和变量解释
			header: {
				entrustNum: '委托单号',
				entrustUnit: '委托单位',
				state: '状态',
				type: '委托类型',
				principal: '联系人',
				phone: '联系电话',
				// updateTime: '提交时间',
				// createTime: '创建时间',
				creator: '录入人',
				salesman:'业务员',
				verifier:'检定员',
				verification: '委托单详情',
				edit: '操作',

			},
			// 弹出框
			headers: {
				entrustNum: '委托单号',
				entrustUnit: '委托单位',
				state: '状态',
				type: '委托类型',
				principal: '联系人',
				phone: '联系电话',
				// updateTime: '提交时间',
				createTime: '创建时间',
				creator: '录入人',
				salesman:'业务员',
				verifier:'检定员',
			},
			params: {
				page:this.props?.location?.state?.page1 || 1,
				size:10,
				createTimeStart:'',
				createTimeEnd:''
			},
			// 是否绑定左侧区域
			bindArea: true
		},
		topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data??null,
		// topFormItem_1_data:null,
		// 表单参数
		topFormItem_1: {
			modal: true,
			number: 4,
			labelCol: 8,
			layout: "horizontal",
			parameter: [
				{variableName: 'entrustNum', str: '委托单号', rules: TYPE_NAME.NAME, data:[],},
				{variableName: 'entrustUnit', str: '委托单位', rules: TYPE_NAME.NAME, data:[],},
				{variableName: 'state', str: '状态', rules: TYPE_NAME.SELECT,
					data:[
						{
							key: '1',
							value: '待接单'
						},
						{
							key: '2',
							value: '检定中'
						},
						{
							key: '3',
							value: '检定完成'
						},
						{
							key: '4',
							value: '委托作废'
						}
					],
				},
				{variableName: 'createTime', str: '创建时间', rules: TYPE_NAME.RANGE_PICKER, data:[],},

			]
		},

		// 条件选择器参数-现状无使用
		conditionsParamenter: {
			parameter: [
				{
					type: TYPE_NAME.NAME,
					variableName: 'entrustNum',
					str: '委托单号',
				},
				{
					type: TYPE_NAME.NAME,
					variableName: 'entrustUnit',
					str: '委托单位'
				},
				{
					type: 'select',
					variableName: 'state',
					str: '状态',
					data: [
						{
							key: '1',
							value: '待接单'
						},
						{
							key: '2',
							value: '检定中'
						},
						{
							key: '3',
							value: '检定完成'
						},
						{
							key: '4',
							value: '委托作废'
						}
					]
				},
				{
					type: 'rangePicker',
					variableName: 'createTime',
					time: false,
					str: '时间'
				},

			]
		},

		totalCount: 0,	   // 总条数
		voidVisible:false, //作废窗口开关器-1
		voidId:'',
		entrustInvalidVisible:false, //结单窗口开关器-1
		entrustInvalidId:'',
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
		deleteEntrustDisplay:'none',

	};
	componentDidMount(){

		if(this.props?.location?.state?.topFormItem_1_data){
			console.log('--------------');
			this.getConditionsValue();
		}else{
			this.table.refreshTable();
		}
		// var params=JSON.parse(sessionStorage.getItem("params"))??[];
		// console.log('params');
		// console.log(params);
		// params.map(one=>{if(one.name==='entrustManagement'){
		// 	console.log('--------------');
		// 	console.log(one.params);
		//
		// 	this.setState({topFormItem_1_data:one.params});
		// 	this.getConditionsValue(one.params);
		// }});
		var menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
		// 判断用户是否具备删除权限
		menuAuthories.map(one=>{
			if(one.name==='报警器检定管理'){
				one.children.map(two=>{
					if(two.name==='删除委托单'){
						this.setState({deleteEntrustDisplay:''})
					}
				});
			}
		});
	}
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {
			case 'verifier':

				const title = text?.substring(1,text?.length-1) || '-';
				return <>
					<div className={'table_overflow'} title={title}>{title}</div>
				</>;
			case 'verification':
			text = rowData.state*1;
			return <>
			{text==3|| text==4? <NavLink to={{
					pathname: "/verifvication/verificationRecordManagementToVoid",
					state: {
						id: rowData.id,
						page1:this.state.tableParamenter.params.page,
					}
				}}>
				<div className={'tableLink'} title={'检定'}>
						<u>{'检定'}</u>
				</div>
				</NavLink>
				: <NavLink to={{
					pathname: "/verifvication/verificationRecordManagement",
					state: {
						id: rowData.id,
						page1:this.state.tableParamenter.params.page,
						topFormItem_1_data:this.state.topFormItem_1_data
					}
				}}>
				<div className={'tableLink'} title={'检定'}>
						<u>{'检定'}</u>
				</div>
				</NavLink>}
				{/*<div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData.id)}>*/}
				{/*		<u>{'下载'}</u>*/}
				{/*</div>*/}
				{/*<div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData.id)}>*/}
				{/*		<u>{'预览'}</u>*/}
				{/*</div>*/}
			</>;
			case 'state':
				return text === '待接单' ? <span style={{color:'#E59722'}}>待接单</span> : text === '检定中' ? <span style={{color:'#DF6A5B'}}>检定中</span> : text === '检定完成' ? <span style={{color:'#44A072'}}>检定完成</span> : text === '作废' ? <span style={{color:'#999999'}}>作废</span>: '-';
			case 'edit':
				text = rowData.state;
				return <>
				{/* <NavLink to={{
					pathname: "/meter/meterBusiness",
					state: {
						customerId: rowData.customerId,
						meterId: rowData.id,
						mpId: rowData.mpId,
						steelNum: rowData.steelNum,
					}
				}}>
				<div className={'tableLink'} title={'修改'}>
						<u>{'修改'}</u>
				</div>
				</NavLink> */}
				{/* <div className={'tableLink'} title={'作废'} onClick={() => this.toVoidStatus(true,rowData.id)}>
						<u>{'作废'}</u>
				</div> */}
				{text=='作废'||text=='检定完成' ? <div className={'tableNoLink'} title={'作废'}>
				<u>{'作废'}</u>
				</div>
				: <div className={'tableLink'} title={'作废'} onClick={() => this.toVoidStatus(true,rowData.id)}>
				<u>{'作废'}</u>
				</div>}
				{/*--------------------------------------------------------------------------------------------------*/}
				{text=='作废'||text=='检定完成'? <div className={'tableNoLink'} title={'结单'}>
				<u>{'结单'}</u>
				</div>
				: <div className={'tableLink'} title={'结单'} onClick={() => this.entrustInvalidStatus(true,rowData.id)}>
				<u>{'结单'}</u>
				</div>}
				{/*--------------------------------------------------------------------------------------------------*/}
				{/* <div className={'tableLink'} title={'结单'} onClick={() => this.entrustInvalidStatus(true,rowData.id)}>
						<u>{'结单'}</u>
				</div> */}
				{text==3|| text==4? <NavLink to={{
					pathname: "/verifvication/verificationRecordManagementToVoid",
					state: {
						id: rowData.id,
						copy:true
					}
				}}>
				<div className={'tableLink'} title={'复制'}>
						<u>{'复制'}</u>
				</div>
				</NavLink>
				: <NavLink to={{
					pathname: "/verifvication/verificationRecordManagement",
					state: {
						id: rowData.id,
						copy:true
					}
				}}>
				<div className={'tableLink'} title={'复制'}>
						<u>{'复制'}</u>
				</div>
				</NavLink>}
				{/*--------------------------------------------------------------------------------------------------*/}

					<Popconfirm
						title="是否确定删除"
						description="是否确定删除?"
						onConfirm={()=>{
							removeEntrust({entrustId:rowData.id}).then(response=>{
								if(response?.data?.data){
									message.info('删除成功')
								}else{
									message.info('删除失败')
								}
								this.table.refreshTable(this.state.tableParamenter.params);
							}).catch(e=>{message.info('异常问题，请重新操作')})
						}}
						// onCancel={cancel}
						okText="是"
						cancelText="否"
					>
						<div className={'tableLink'}
							 title={'删单'}
							 style={{display: this.state.deleteEntrustDisplay}}
						>
							<u>{'删单'}</u>
						</div>
					</Popconfirm>

				</>;

			default:
				return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};

	updatePoint = () => {
		let rowData = this.table.getSelectChange();
		if(rowData.length < 1) return message.error('未选择数据');
		let data = {
			name: rowData[0].name,
			id: rowData[0].id,
			dataKey: "2"
		};
		this.props.history.push({
			pathname: '/device/addDataItem',
			state: data
		})
	};

	onChangeSelect = e => {
		console.log(111,e)
	};

	// 条件选择：获取输入的条件
	getConditionsValue = async value => {
		let data =value?? await this.forms.onFinishNotReset();
		let sTime=data.createTime ?moment(data.createTime[0]._d) : null;
		let eTime=data.createTime ?moment(data.createTime[1]._d) : null;
		let params = this.state.tableParamenter.params;
		let formParams=this.table.getParams();
		Object.assign(params,data);
		params.page=formParams.page;
		params.size=formParams.size;
		params.createTime='';
		params.createTimeStart=sTime;
		params.createTimeEnd=eTime;

		params.salesman =this.state.salesmanDates || null;
		params.verifier =this.state.verifierDates || null;
		let {tableParamenter} = this.state;
		tableParamenter.params = params;
		console.log(params);
		// var paramss=JSON.parse(sessionStorage.getItem("params"))??[];
		// if(paramss.find(one=>{if(one.name==='entrustManagement')return true})){
		// 	console.log('11');
		// 	paramss.map(one=>{if(one.name==='entrustManagement'){
		// 		one.params=params;
		// 	}})
		// }else{
		// 	console.log('22');
		//
		// 	paramss.push({
		// 		name:'entrustManagement',
		// 		params: params
		// 	});
		// }
		// sessionStorage.setItem("params",JSON.stringify(paramss));
		this.setState({tableParamenter,topFormItem_1_data:params});
		await this.table.refreshTable(params);

	};
    getAreaId = () => {
		Pubsub.subscribe('areaIds', (_, data) => {
		  let {params, initParams} = this.state;
		  params.areaIds = data;
		  params.page = 1;
		  params.size = initParams.size;
		  this.setState({params});
		  this.getData()
		})
	  }
// 初始化表格数据

	getData = async () => {
		this.setState({})
		this.table.refreshTable();
	// this.setState({loading: true})
	// this.getAreaId()
	// let menusStr = localStorage.getItem('menus')
	// let menus = ((menusStr != null || menusStr != "null") && typeof menusStr == 'string') ? JSON.parse(localStorage.getItem('menus')) : []
	// let {params} = this.state
	// params.areaIds=menus
	// getDataItemList(params).then(
	//   response => {
	// 	const {data} = response.data
	// 	const {pageInfo} = response.data
	// 	let total= pageInfo.total
	// 	let {tableParamenter} = this.state
	// 	tableParamenter.data = data
	// 	this.setState({tableParamenter,totalCount:total,loading: false})
	// 	message.success('查询成功',[0.5])
	//   }
	// ).catch(
	//   () => {
	// 	this.setState({
	// 	  loading: false,
	// 	  refreshTable: false,
	// 	})
	// 	message.error('查询失败',[0.5])
	//   }
	// )
  };
    // 作废委托单
	toVoidStatus=(voidVisible,id)=>{
		this.setState({voidVisible,voidId:id});
	};
	toVoid=(voidVisible)=>{
		cancellationEntrust({entrustId: this.state.voidId}).then(
				response => {
					console.log(response)
					this.setState({voidVisible})
					// funTraceInfo("报警器检定管理", "作废委托单", null,this.state.voidId)
					message.success('操作成功')
					this.table.refreshTable();
				}
			)
	};
    // 结单
	entrustInvalidStatus=(entrustInvalidVisible,id)=>{
		this.setState({entrustInvalidVisible,entrustInvalidId:id});
	};
	entrustInvalid=(entrustInvalidVisible)=>{
		getEntrustInvalid({entrustId: this.state.entrustInvalidId}).then(
				response => {
					console.log(response)
					this.setState({entrustInvalidVisible})
					// funTraceInfo("报警器检定管理", "结单", null,this.state.entrustInvalidId)
					message.success('操作成功')
					this.table.refreshTable();
				}
			)
	};



	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/downloadByEntrustId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByEntrustId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/previewByEntrustId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByEntrustId')
	};


	// 清空条件
	clearCondition = () => {
		let {params} = this.state;
		// params.relevanceInfoAssociationName=null,
		// params.alarmLevel=null,
	   this.setState({params:params});
	   this.getData();
	};
    render() {
        return (
            <Layout className={'item_body_number_back tables'}>
				<div className={'topSearchBox'}>
				<h3>委托单管理</h3>
				{/*<Conditions*/}
                {/*        conditionsParamenter={this.state.conditionsParamenter} // 条件框输入框设置*/}
                {/*        getConditionsValue={this.getConditionsValue} // 获取条件框输入的值*/}
                {/*        clearCondition={this.clearCondition} // 清除条件*/}
                {/*    >*/}
				{/*</Conditions>*/}
				<div style={{width:'100%'}}>
					{/*查询表单*/}
					<div style={{width:'80%'}}>
						<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.topFormItem_1}
							data={this.state.topFormItem_1_data}
						/>
					</div>
					<div style={{width:'24%'}}>
						<div style={{width:'100%'}}>
							<div style={{width:'26%',float:'left',textAlign:'right'}}>
								<span style={{fontSize:'16px', paddingRight: '8px'}}>业务员:</span>
							</div>
							<div style={{width:'70%',float:'left'}}>
								<Select
									style={{width:'100%'}}
									// mode="multiple"
									allowClear
									value={this.state.salesmanDates}
									// status={this.state.customerData[9].type}
									// maxTagCount={2}
									placeholder={'请输入业务员'}
									onChange={(title,data)=>{
										// var customerData = this.state.customerData;


										this.setState({salesmanDates:title});
									}}
								>
									<Option key={'韦杰'} label={'韦杰'}>韦杰</Option>
									<Option key={'林迪启'} label={'林迪启'}>林迪启</Option>
									<Option key={'林启迪'} label={'蒋宁湘'}>蒋宁湘</Option>
									<Option key={'熊攻科'} label={'熊攻科'}>熊攻科</Option>
									<Option key={'杨能'} label={'杨能'}>杨能</Option>
									<Option key={'程立国'} label={'程立国'}>程立国</Option>
									<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
									<Option key={'李梦婷'} label={'李梦婷'}>李梦婷</Option>
									<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
									<Option key={'李进'} label={'李进'}>李进</Option>
									<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
									<Option key={'田辉'} label={'田辉'}>田辉</Option>
									<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
									<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
									<Option key={'马琼'} label={'马琼'}>马琼</Option>
									<Option key={'杨超'} label={'杨超'}>杨超</Option>
									<Option key={'其他'} label={'其他'}>其他</Option>
								</Select>
							</div>
						</div>
					</div>
					<div style={{width:'24%',float:'left'}}>
						<div style={{width:'100%'}}>
							<div style={{width:'25%',float:'left',textAlign:'right'}}>
								<span style={{fontSize:'16px', paddingRight: '8px'}}>检定员:</span>
							</div>
							<div style={{width:'70%',float:'left'}}>
								<Select
										style={{width:'100%'}}
										mode="multiple"
										allowClear
										value={this.state.verifierDates}
										// status={this.state.customerData[9].type}
									// maxTagCount={2}
										placeholder={'请输入检定员'}
										onChange={(title,data)=>{
											// var customerData = this.state.customerData;
											if(title?.length>3){
												return message.info('仅限三位');
											}

											this.setState({verifierDates:title});
										}}
								>
									<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
									<Option key={'杨超'} label={'杨超'}>杨超</Option>
									<Option key={'马琼'} label={'马琼'}>马琼</Option>
									<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
									<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
									<Option key={'田辉'} label={'田辉'}>田辉</Option>
									<Option key={'杨能'} label={'杨能'}>杨能</Option>
									<Option key={'程立国'} label={'程立国'}>程立国</Option>
									<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
									<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
									<Option key={'李进'} label={'李进'}>李进</Option>
								</Select>
							</div>
						</div>
					</div>
					<div style={{width:'15%',float:'right',marginLeft:'10px'}}>
						<Button type="primary" onClick={() => this.getConditionsValue()}>查询</Button>
						<Button onClick={async () => {

							this.setState({salesmanDates:null,verifierDates:[],topFormItem_1_data:null});
							await this.forms.onModalClose();
							await this.forms.onModalClose();
							this.getConditionsValue()
						} }>清空</Button>
					</div>
				</div>

				</div>
				<div className={'topBtnBox'}>
						<NavLink to="/verifvication/addEntrust">
							<span value="addDataItem" onClick={()=>{allDelTemporaryAlarm()}}><PlusSquareOutlined />创建委托单</span>
						</NavLink>
						{/*<span><ExportOutlined />任务派发</span>*/}
				</div>
				{/*表格----------------*/}
				<div>
					<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getEntrustList}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
					</TableComponents>
				</div>
				{/*弹窗----------------*/}
				<Modal
					maskClosable={false}
                        title='作废'
                        style={{ top: 0 }}
                        visible={this.state.voidVisible}
                        okText="确定"
                        onOk={() => this.toVoid(false)}           // 点击确定
                        onCancel={() => this.toVoidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认作废该委托单吗？</h4>
                        </div>
                </Modal>
				<Modal
					maskClosable={false}
                        title='结单'
                        style={{ top: 0 }}
                        visible={this.state.entrustInvalidVisible}
                        okText="确定"
                        onOk={() => this.entrustInvalid(false)}           // 点击确定
                        onCancel={() => this.entrustInvalidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认结单吗？</h4>
                        </div>
                </Modal>
				<Modal
					maskClosable={false}
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpenNull(null,1)}>模板</span>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0)}>委托单</span>
                        </div>
                    </Modal>
					<Modal
						maskClosable={false}
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpenNull(null,1)}>模板</span>
							<span onClick={() => this.previewOpen(this.state.previewId,0)}>委托单</span>
                        </div>
                    </Modal>
            </Layout>
        )
    }
}
