// 委托单下的检定报警器管理
import React, { Component } from 'react'
import {
	Layout,
	Button,
	Tabs,
	Input,
	Col,
	Row,
	message,
	Modal,
	Select,
	DatePicker,
	Spin,
	AutoComplete,
	Popconfirm
} from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import TableComponents from '../../../components/common/tables/TableComponentsNew'
import FormsTwo from '../../../components/common/forms/FormsTwo'
import { NavLink } from "react-router-dom"
import areaData from "../standby/addreData";
import {TYPE_NAME} from '../../../components/Const'
import {
	getVerificationRecordById,
	addGasAlarmRecord,
	getAlarmByStratNumber,
	getCustomer,
	getCustomerOne,
	AlarmRecordInInvalid,
	updateEntrust,
	insertEntrust,
	copyEntrustInfo,
	getEquipmentName,
	getModelByAlaramId,
	addAlarmInfo, removeAlarm
} from '../../../services/verifvication'
import {saveDataItem,getOne} from '../../../services/device'
import {PlusSquareOutlined,ExportOutlined} from '@ant-design/icons';
import { data } from 'jquery';
import {funTraceInfo} from '../../../services/common'
import {Option} from "antd/es/mentions";
const { TabPane } = Tabs;
// var downIp='http://47.97.117.248:9000/managment'
var downIp =global.pubData.url;
export default class AddPoint extends Component {

    state = {
		saveDisabled:true,// 保存按钮可使用状态
		noMsgJuder:true,
		juder_1:true,
		entrustInfo:{},// 委托信息
		select_equipmentName:[],
		select_model:[],
		variableVisible_2:false,
		// 添加设备信息
		addEquipment:{
			equipmentName:null, // 设备名称
			model:null,			// 型号
			startNumber:null,	// 出厂编号
			manufacturers:null,	// 厂家
			// location:'用户使用现场',		// 安装位置
			province:'湖南省',		// 省
			city:'长沙市',			// 市
			district:null,		// 区
			details:null,		// 详情
			verifierDate:null	// 检定日期
		},

				// 表格参数
				tableParamenter: {
					// columnMaxWith: 800,
					manageName: "数据项管理",
					// 表格行是否可选
					// rowSelection: true,
					//下拉框长度
					selectWidth: '6.5vw',
					// 表头：变量和变量解释
					header: {
						// id: '序号',
						equipmentName: '设备名称',
						model: '型号规格',
						manufacturers: '制作厂家',
						startNumber: '出厂编号',
						type: '类型',
						state: '状态',
						creator:'录入人',
						verifier: '检定员',
						check: '核验员',
						edit: '操作',

					},
					// 弹出框
					headers: {
						// id: '序号',
						equipmentName: '设备名称',
						model: '型号规格',
						manufacturers: '制作厂家',
						startNumber: '出厂编号',
						type: '类型',
						state: '状态',
						verifier: '检定员',
						check: '核验员',
					},
					// 是否绑定左侧区域
					bindArea: false,
					params: {
						entrustId: this.props.location.state?.id,	// 委托单
						page:this.props?.location?.state?.page || 1,
						size:10,
					}
				},
				// 总条数
				totalCount: 0,

				// params: {
				// 	entrustId:'',	// 委托单
				// 	page:1,
				// 	size:10,
				// 	// page:{
				// 	// 	page:1,
				// 	// 	size:10,
				// 	// }

				// 	// username: null,
				// 	// organizationId :this.state.treeId
				// 	// areaId: localStorage.getItem('menus')
				// },
        // 表单参数
		formParamenter: {
			modal: true,
			number: 4,
            labelCol: 7,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'customerId',
					str: '委托单位',
					disabled: true,
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.onChangeSelect(k,v)
				},
				// {
				// 	variableName: 'entrustUnit',
				// 	str: '委托单位',
				// 	disabled: true,
				// 	rules: TYPE_NAME.INPUT,
				// },
				{
					variableName: 'principal',
					str: '联系人',
					rules: TYPE_NAME.INPUT,

				},
				{
					variableName: 'phone',
					str: '联系电话',
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'province',
					str: '省',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					disabled: true,
					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'verificationQuantity',
					str: '检定仪器数量',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		        // 表单参数
		// formParamenter: {
		// 	modal: true,
		// 	number: 4,
        //     labelCol: 7,
		// 	layout: "horizontal",
		// 	// itemSize: 'large',
		// 	parameter: [
		// 		{
		// 			variableName: 'id',
		// 			str: '委托单位',
		// 			rules: TYPE_NAME.INPUT,
		// 		},
		// 		{
		// 			variableName: 'name',
		// 			str: '联系人',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50,
		//
		// 		},
		// 		{
		// 			variableName: 'unit',
		// 			str: '联系电话',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'remark',
		// 			str: '单位电话',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'remark',
		// 			str: '客户地址',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 		{
		// 			variableName: 'searchName',
		// 			str: '检定仪器数量',
		// 			rules: TYPE_NAME.LABLE_NOT_NULL_50
		// 		},
		// 	]
		// },
        // 弹框表单参数
		ModalFormParamenter: {
			modal: true,
			number: 3,
            labelCol: 8,
			layout: "horizontal",
			// itemSize: 'large',
			parameter: [
				{
					variableName: 'startNumber',
					str: '出厂编号',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				{
					variableName: 'equipmentName',
					str: '设备名称',
					rules: TYPE_NAME.SELECTINPUT,
					data:[],
					onChange: (k,v) => this.equipmentIdSelect(k,v)
				},
				{
					variableName: 'model',
					str: '型号规格',
					rules: TYPE_NAME.AutoComplete,
					data:[],
					// onChange: (k,v) => this.onChangeSelect(k,v)

				},
				{
					variableName: 'manufacturers',
					str: '制造厂家',
					rules: TYPE_NAME.LABLE_NOT_NULL_50
				},
				// {
				// 	variableName: 'location',
				// 	str: '安装位置',
				//
				// 	rules: TYPE_NAME.INPUT
				// },
				{
					variableName: 'province',
					str: '省',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'city',
					str: '市',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'district',
					str: '区',

					rules: TYPE_NAME.INPUT
				},
				{
					variableName: 'details',
					str: '详细地址',
					rules: TYPE_NAME.INPUT
				},
			]
		},
		data: {
			customerId: ''
		},
		dataKey: this.props.location.state?.dataKey ?? '1',
		devVisible:false, // 小窗口开关器-1
		voidVisible:false, //作废窗口开关器-1
		voidId:'',
		downloadVisible:false, //下载窗口开关器-1
		downloadId:'',
		previewVisible:false, //预览窗口开关器-1
		previewId:'',
		voGasAlarmRecordList:[],
		equipmentName:'',
		showVal:'none',
		deleteDeviceDisplay:'none',
		buttonJuder_1:'none'
    };

	componentDidMount(){
		console.log('this.table.getTotalCount()');
		console.log(this.props?.location?.state?.topFormItem_1_data);


		var menuAuthories = JSON.parse(sessionStorage.getItem("menuAuthories"));
		console.log(menuAuthories);
		menuAuthories.map(one=>{
			if(one.name==='报警器检定管理'){
				one.children.map(two=>{
					if(two.name==='删除设备'){
						this.setState({deleteDeviceDisplay:''})
					}else if(two.name==='核验按钮'){
						this.setState({buttonJuder_1: ''})
					}
				});
			}
		});


		const {name, id} = this.props.location?.state ?? {};
		let {tableParamenter} = this.state;
		tableParamenter.params.entrustId = id;
		this.setState({tableParamenter});
		if(id) {
			this.getData(id)
		}
		// 获取客户信息
		getCustomer().then(
			response => {
				if(response?.data?.data){
					const {data} = response.data;
					let selectArry=[];
					console.log(data);
					if(data){
						for(var i=0;i<data.length;i++){
							selectArry.push({key:data[i].id,value:data[i].customerUnit})
						}
					}

					this.state.formParamenter.parameter[0].data=selectArry;
					this.setState({saveDisabled:false})
				}else{
					message.info('未能获取客户信息,请刷新页面')
				}
			}
		);
		getEquipmentName().then(
			response => {
				const {data} = response.data
				let selectArry=[]
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i].id,value:data[i].equipmentName})
					}
				}
				this.state.select_equipmentName=selectArry;
				this.state.ModalFormParamenter.parameter[1].data=selectArry;
			}
		);
	}
	equipmentIdSelect = (k,v) => {
		console.log(k);
		console.log(v);
		getModelByAlaramId({alaramId: k}).then(
			response => {
				console.log(k,v,response);
				const {data} = response.data;
				let selectArry=[];
				if(data){
					for(var i=0;i<data.length;i++){
						selectArry.push({key:data[i]?.devModelName,value:data[i]?.devModelName})
					}
				}
				// data.model=null
				this.state.equipmentName=v.children;
				if(data){
					// this.setState({data: data});
					this.state.select_model=data;
					this.state.ModalFormParamenter.parameter[2].data=selectArry;
				}

			}
		)
	};
	onChangeSelect = (k,v) => {
		getCustomerOne({id: k}).then(
			response => {
				const {data} = response.data
				let selectData=this.state
				selectData.customerId=data.id
				selectData.entrustUnit=data.customerUnit
				selectData.principal=data.principal
				selectData.phone=data.principalPhone
				selectData.province=data.province
				selectData.city=data.city
				selectData.district=data.district
				selectData.details=data.details
				this.setState({data: selectData})
				this.forms.onModalClose()
			}
		)
	};
	setTableValue = (value,text,rowData) => {
		if(text === 'null') return '-';
		switch (value) {

			case 'type':
				var title = text === '1' ? '首次检定' : text === '2' ?'后续检定' : text === '3' ?'使用中检查' :'-';
				return <>
					<div className={'table_overflow'} title={title}>
						{title}
					</div>
				</>;

			case 'edit':
				text = rowData.state;

				// text === '1' ? <span style={{color:'#E59722'}}>待检定</span>
				// 	: text === '2' ? <span style={{color:'#DF6A5B'}}>待核验</span>
				// 	: text === '3' ? <span style={{color:'#44A072'}}>待审批</span>
				// 		: text === '4' ? <span style={{color:'#999999'}}>审批通过</span>
				// 			: text === '5' ? <span style={{color:'#999999'}}>审批失败</span>
				// 				:text === '6' ? <span style={{color:'#999999'}}>作废</span>
				// 					:text === '7' ? <span style={{color:'#E59722'}}>核验驳回</span>
				// 						:'-'

				rowData.alarmState = rowData.state==='待检定'?"1"
					:rowData.state==='待核验'?"2"
						:rowData.state==='待审批'?"3"
							:rowData.state==='审批通过'?"4"
								:rowData.state==='审批失败'?"5"
									:rowData.state==='作废'?"6"
										:rowData.state==='核验驳回'?"7":null;
				return <>
					<div>


				{/* <div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData.id)}>
						<u>{'下载'}</u>
				</div>
				<div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData.id)}>
						<u>{'预览'}</u>
				</div> */}

				{text=='核验通过' ? <div className={'tableLink'} title={'下载'} onClick={() => this.downloadStatus(true,rowData.id)}>
						<u>{'下载'}</u>
				</div>
				: <div className={'tableNoLink'} title={'下载'}>
					<u>{'下载'}</u>
					</div>}

				{text=='核验通过' ? <div className={'tableLink'} title={'预览'} onClick={() => this.previewStatus(true,rowData.id)}>
				<u>{'预览'}</u>
				</div>
				: <div className={'tableNoLink'} title={'预览'}>
					<u>{'预览'}</u>
				</div>}

				{
					(text==='核验驳回' || text==='待检定')  && ( rowData.entrustState!=='检定完成' && rowData.entrustState!=='作废') ?
					<NavLink to={{
						pathname: "/verifvication/verificationRegistration",
						state: {
							topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data,
							page:this.state.tableParamenter.params.page,	// 当前页面
							page1:this.props?.location?.state?.page1 || 1,	// 上一页
							pathname: '/verifvication/verificationRecordManagement',
							entrustId: this.props?.location?.state?.id,
							id: rowData.id,
							rowVal:rowData,
							alarmState:rowData.alarmState,
							reason:rowData?.voRejects[rowData?.voRejects.length-1]?.reason || null
						}
					}}>
						<div className={'tableLink'} title={'检定'}>
							<u>{'检定'}</u>
						</div>
					</NavLink>
				: <div className={'tableNoLink'} title={'检定'}>
					<u>{'检定'}</u>
					</div>
				}

				{text=='待核验' && rowData.entrustState!='检定完成' && rowData.entrustState!='作废' ? <NavLink to={{
					pathname: "/verifvication/verificationRegistration",
					state: {
						topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data,
						page:this.state.tableParamenter.params.page,
						page1:this.props?.location?.state?.page1 || 1,	// 上一页
						pathname:'/verifvication/verificationRecordManagement',
						entrustId: this.props?.location?.state?.id,
						id: rowData.id,
						alarmState:rowData.alarmState,
						customerId:this.state.data.customerId,
						formData:true,
						status:2
					}
				}}>
						<div className={'tableLink'} title={'核验'} style={{display: this.state.buttonJuder_1}}>
							<u>{'核验'}</u>
						</div>
				</NavLink>
				:
					<div className={'tableNoLink'} title={'核验'} style={{display: this.state.buttonJuder_1}}>
						<u>{'核验'}</u>
					</div>
				}

				{
					text==='作废' || rowData.entrustState==='检定完成' ||
				rowData?.entrustState === '作废'
					?
					<div className={'tableNoLink'} title={'作废'}>
						<u>{'作废'}</u>
				</div>
				: <div className={'tableLink'} title={'作废'} onClick={() => this.toVoidStatus(true,rowData.id)}>
				<u>{'作废'}</u>
				</div>}

				<Popconfirm
							title="是否确定删除"
							description="是否确定删除?"
							onConfirm={()=>{
								 removeAlarm({gasId:rowData.id}).then(response=>{
									 if(response?.data?.data){
										 message.info('删除成功')
									 }else{
										 message.info('删除失败')
									 }
									 this.table.refreshTable(this.state.tableParamenter.params);
								 }).catch(e=>{message.info('异常问题，请重新操作')})
							}}
							// onCancel={cancel}
							okText="是"
							cancelText="否"
				>
					<div className={'tableLink'}
						 title={'删除设备'}
						 style={{display: this.state.deleteDeviceDisplay}}
					>
						<u>{'删除设备'}</u>
					</div>
				</Popconfirm>

					</div>
				</>;



		default:
		return text ? <div className={'table_overflow'} title={text}>{text}</div> : ''
		}
	};
	getData = id => {
		getVerificationRecordById({entrustId: id,page:1,size:10}).then(
				response => {
					const {data} = response.data;
					console.log(data);
					if(data){
						if(data.entrustState==="检定完成" || data.entrustState==='作废'){
							this.setState({showVal:'none'});

						}else{
							this.setState({showVal:'block'});
						}
						var params =[];
						if(data?.verifier){
							data?.verifier?.substring(1,data?.verifier.length-1).split(",")?.map(one=>{
								params.push(one.trim())
							});
							data.verifier=params;
						}

						// data.verifier=data?.verifier.substring(2,data.verifier.length-2).split(",");

						this.setState({customerId: data?.customerId});
						this.setState({voGasAlarmRecordList: data?.voGasAlarmRecordList});
						this.setState({data: data});
						// this.forms.onModalClose()
					}

				}
			)
	};

	//创建委托单
    onSave = async () => {
		let user = JSON.parse(sessionStorage.getItem("user"));

		var data = this.state.data;
		data.creator=user.realName;

		if(data?.voGasAlarmRecordList){
			data?.voGasAlarmRecordList.map((one)=>{
				one.state=1;
			})
		}
		copyEntrustInfo(data).then(data=>{
			if(data?.data){
				if(data?.data?.responseInfo.code===200){
					message.success("创建成功");
					funTraceInfo("报警器检定管理", "委托单创建", null,data);
					this.props.history.go(-1)
				}else {
					message.success("创建失败");
					this.table.refreshTable();// 刷新
				}

			}else {
				message.success("创建失败，请重新操作");
			}

		})

		// 后期可删除，已将修改客户信息和保存委托单分离
		// let user = JSON.parse(sessionStorage.getItem("user"));
		// const {id,copy} = this.props.location?.state ?? {};
		// let values = await this.forms.onFinishNotReset();
		// console.log(this.state.formParamenter);
		//
		// for(var i =0;i<this.state.formParamenter.parameter[0].data.length;i++){
		// 	if(this.state.formParamenter.parameter[0].data[i].key===values.customerId){
		// 		values.entrustUnit=this.state.formParamenter.parameter[0].data[i].value;
		// 	}
		// }
		// values.creator=user.realName;
		// values.voGasAlarmRecordList=this.state.voGasAlarmRecordList;
		// values.id=id;
		// console.log('values-------------------');
		// console.log(values);
		// console.log('values-------------------');
    	// if(copy===true){
		// 	values.id=null;
		// 	copyEntrustInfo(values).then(data=>{
		// 		if(data.data===true){
		// 			message.success("创建成功");
		// 			funTraceInfo("报警器检定管理", "委托单创建", null,values)
		// 		}else {
		// 			message.success("创建失败");
		// 			this.table.refreshTable();// 刷新
		// 		}
		// 		this.props.history.go(-1)
		// 	})
		// }else{
		// updateEntrust(values).then(data=>{
		// 	if(data.data.data===true){
		// 		message.success("创建成功");
		// 		funTraceInfo("报警器检定管理", "委托单修改", null,values)
		// 		this.table.refreshTable();// 刷新
		// 	}else {
		// 		message.error("创建失败");
		// 		this.table.refreshTable();// 刷新
		// 	}
		// 	this.props.history.go(-1)
		// })
		// }

    };
	//添加设备窗口显示
	addQrcodeStatus=(devVisible)=>{
		var getDataTable = this.table.getDataTable();
		console.log(getDataTable);
		console.log('添加设备窗口显示');

		var addEquipment = this.state.addEquipment;
		var data = this.state.data;
		// 根据表单第一条内容封装
		if(getDataTable.length!==0){

			addEquipment.equipmentName=getDataTable[0].equipmentName || null; 	// 设备名称
			addEquipment.model=getDataTable[0].model || null;		  			// 型号
			addEquipment.startNumber=getDataTable[0].startNumber || null;	  	// 出厂编号
			addEquipment.manufacturers=getDataTable[0].manufacturers || null; 	// 厂家
			// addEquipment.location=getDataTable[0].location || '用户使用现场';	// 安装位置
			// 判断有没有选择区域，没有默认
			if(getDataTable[0]?.province){
				addEquipment.province=getDataTable[0].province || null;	  		// 省
				addEquipment.city=getDataTable[0].city || null;		  			// 市
				addEquipment.district=getDataTable[0].district || null;	  		// 区
				addEquipment.details=getDataTable[0].details || null;		  	// 详情
			}else{
				addEquipment.province=data?.province || '湖南省';	  			// 省
				addEquipment.city=data?.city || '长沙市';		  				// 市
				addEquipment.district=data?.district || '岳麓区';	  			// 区
				addEquipment.details=data?.details || null;		  				// 详情
			}


			if(addEquipment?.equipmentName){
				var selectEquipmentName = this.state?.select_equipmentName;
				console.log(selectEquipmentName);
				selectEquipmentName.map(one=>{
					if(one.value===addEquipment?.equipmentName){
						this.equipmentIdSelect(one.key,one);
					}
				})
			}
			this.setState({addEquipment:addEquipment})

		}else {
			addEquipment.province=data?.province || '湖南省';	  			// 省
			addEquipment.city=data?.city || '长沙市';		  				// 市
			addEquipment.district=data?.district || '岳麓区';	  			// 区
			addEquipment.details=data?.details || null;		  		// 详情
		}
        this.setState({variableVisible_2:true});
		this.findArea();
    };

	findArea=()=>{
		var addEquipment = this.state.addEquipment;
		var {Equipment_city,Equipment_district} =this.state;
		areaData.map(one=>{
			// 市级赋值
			if(one.name===addEquipment.province){
				Equipment_city=one.city;
				Equipment_city.map(two=>{
					if(two.name===addEquipment.city){
						Equipment_district=two.area;
					}
				})

			}
		});
		this.setState({Equipment_city,Equipment_district})

	};
	addGasAlarmData = async (value)=>{
		const {id} = this.props.location?.state ?? {};
		// 旧
		// let devData = await this.forms.onFinishNotReset();
		// if(!devData) return null;
		// devData.entrustId=id;
		// devData.equipmentName=this.state.equipmentName;
		// console.log(devData);

		// 新
		var devData = this.state.addEquipment;
		if(!devData?.equipmentName){
			this.setState({juder_3_1:'error'});
			return message.info('请选择设备');
		}
		if(!devData?.model){
			this.setState({juder_3_2:'error'});
			return message.info('请选择型号规格');
		}
		if(!devData?.startNumber){
			this.setState({juder_3_3:'error'});
			return message.info('请选择出厂编号');
		}
		devData.entrustId=id;
		this.setState({variableVisible_2:false});

		var user = JSON.parse(sessionStorage.getItem("user"));
		console.log(user);
		devData.creator=user.username;
		console.log(devData);

		getAlarmByStratNumber({startNumber:devData.startNumber,entrustId:id}).then(response=>{
			console.log(response);
				if(response.data.data===true){
					addGasAlarmRecord(devData).then(data=>{
						console.log(data);
						if(data?.data===true){
							message.config("添加成功");
							funTraceInfo("报警器检定管理", "添加报警器记录", null,devData)
							this.setState({variableVisible_2:false});
						}else{
							this.setState({variableVisible_2:false});
							message.config('添加失败')
						}
						this.table.refreshTable();// 刷新表格
					});
				}else{
					// this.addDevStatus(false);
					message.success('该出厂编号已存在！');
					this.table.refreshTable();// 刷新
				}
		});



		// await this.forms.onModalClose();
	};
	//添加设备窗口显示
    clear = (e, info) => {
		let {formParamenter} = this.state;
		this.setState({dataKey: e});
		this.setState({formParamenter})
    }

	//作废检定窗口显示
	toVoidStatus=(voidVisible,id)=>{
		this.setState({voidVisible,voidId:id});
	};
	toVoid=(voidVisible)=>{
		AlarmRecordInInvalid({gasId: this.state.voidId}).then(
			response => {
				console.log(response)
				this.setState({voidVisible})
				message.success('操作成功')
				// this.forms.onModalClose()
				this.table.refreshTable();// 刷新表格
			}
		)
    };

	//下载窗口
	downloadStatus=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus,downloadId:id});
	};
	download=(downloadStatus,id)=>{
		this.setState({downloadVisible:downloadStatus})
		console.log(this.state.downloadId)
	};

	downloadOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadByAlarmId?id='+alarmId)
	};
	downloadOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/downloadBlankByAlarmId')
	};

	//预览窗口
	previewStatus=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus,previewId:id});
	};
	preview=(previewStatus,id)=>{
		this.setState({previewVisible:previewStatus})
		console.log(this.state.previewId)
	};

	previewOpen=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewByAlarmId?id='+alarmId)
	};
	previewOpenNull=(alarmId,isNotBlank,fileType,needStamp)=>{
		window.open(downIp+'/verification/pdfdownload/previewBlankByAlarmId?id='+alarmId)
	};


    render() {

		const {dataKey} = this.state;

        return (
            <Layout className={'item_body_number_back tables'}>
				{/*<div style={{*/}
				{/*	display:this.state.noMsgJuder? null:'none',*/}
				{/*	width:'100%',*/}
				{/*	height:'1080px',*/}
				{/*	fontSize:'30px',*/}
				{/*	textAlign:'center',*/}
				{/*	overflow:'auto',*/}
				{/*	color:'#ccc',*/}
				{/*	zIndex:'2'*/}
				{/*}}>*/}
				{/*	<Spin />加载中*/}
				{/*</div>*/}
				<div style={{width: '100%',height: '100%',backgroundColor: '#ffffff',padding: '30px 1%'}}>
					<div className={'topTitleBox'}>
						<h3>报警器检定记录管理</h3>
						<div style={{float:'right'}}>
						<Button
							style={{
								margin:'0 0 0 20px',
								display:this.props?.location?.state?.copy?'':'none'
							}}
							loading={this.state?.saveDisabled}
							disabled={this.state?.saveDisabled}
							type="primary"
							onClick={()=>{this.onSave()}}
						>
							保存
						</Button>
						<Button style={{margin:'0 0 0 20px'}} onClick={() => {
							// this.props.history.go(-1);
							this.props.history.push({
								pathname: '/verifvication/entrustManagement',
								state:{
									page1:this.props?.location?.state?.page1,
									topFormItem_1_data:this.props?.location?.state?.topFormItem_1_data
								}
							});
						}}>返回</Button>
						</div>
					</div>
					<div style={{clear:'both',padding:'0 10px',height:'170px'}}>
						<div style={{width:'100%',height:'130px'}}>
							<div style={{width:'100%',height:'40px'}}>
								<div style={{width:'25%'}}>
									<div style={{width:'18%',float:'left',marginTop:'3px'}}>
										<span>委托单位：</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<Input disabled  value={this.state?.data?.entrustUnit} />
									</div>
								</div>
							</div>
							{/*第二层*/}
							<div style={{width:'100%',height:'40px'}}>
								<div style={{width:'25%',float:'left'}}>
									<div style={{width:'18%',float:'left',marginTop:'3px'}}>
										<span>联系人：</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<Input
											status={this.state.principalStatus}
											disabled={this.state?.juder_1}
											value={this.state?.data?.principal}
											onChange={(text)=>{
												var data = this.state?.data;
												data.principal=text.target.value;
												if(text.target.value){
													this.setState({principalStatus:null})
												}
												this.setState({data})
											}}
										/>
									</div>
								</div>
								<div style={{width:'25%',float:'left'}}>
									<div style={{width:'18%',float:'left',marginTop:'3px'}}>
										<span>联系电话：</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<Input
											status={this.state.phoneStatus}
											disabled={this.state?.juder_1}
											value={this.state?.data?.phone}
											onChange={(text)=>{
												var data = this.state?.data;
												if(/^[1]([3-9])[0-9]{9}$/g.test(text.target.value)){
													this.setState({phoneStatus:null})
												}
												data.phone=text.target.value;
												this.setState({data})
											}}
										/>
									</div>
								</div>
								<div style={{width:'25%',float:'left'}}>
									<Button
										type={'primary'}
										style={{border:'1px #1890ff solid',}}
										onClick={()=>{
											if(this.state?.juder_1){
												console.log('修改');
												this.setState({juder_1:false})
											}else{
												console.log('保存');

												if(!this.state.data.principal){
													this.setState({principalStatus:'error'});
													return message.info('联系人不能为空')
												}
												if(!this.state.data.phone){
													this.setState({phoneStatus:'error'});
													return message.info('电话不能为空')
												}
												const values=Object.assign({}, this.state.data);

												values.verifier=this.state.data.verifier;
												this.setState({juder_1:true});
												updateEntrust(values).then(data=>{
													if(data.data.data===true){
														message.info("修改成功");
														funTraceInfo("报警器检定管理", "委托单修改", null,data);
														// this.table.refreshTable();// 刷新
													}else {
														message.error("修改失败");
														const {id} = this.props.location?.state ?? {};
														if(id) {
															this.getData(id);
														}
														// this.table.refreshTable();// 刷新
													}
													// this.props.history.go(-1)
												})
											}

										}}
									>{this.state?.juder_1?'修改部分信息':'保存'}</Button>
									<Button
										style={{display:this.state?.juder_1?'none':null}}
										onClick={()=>{
											const {id} = this.props.location?.state ?? {};

											this.setState({salesmanStatus:null,verifierStatus:null});
											if(id) {
												this.getData(id);
											}
											this.setState({juder_1:true})}
										}
									>取消</Button>
								</div>
							</div>
							{/*第三层*/}
							<div style={{width:'100%',height:'40px'}}>
								<div style={{width:'15%',float:'left'}}>
									<div style={{width:'100%',height:'60%'}}>
										<div style={{width:'30%',paddingTop:'4px',float:'left'}}>
											<span>省:</span>
										</div>
										<div style={{width:'65%',float:'left'}}>
											<Select showSearch
													disabled
													style={{width:'100%'}}
													value={this.state?.data?.province}
													placeholder={'请选择省'}
													options={areaData}
													fieldNames={ {label: 'name', value: 'name', children: 'children'} }
													onChange={(title,valueData)=>{
														var addEquipment = this.state.addEquipment;
														addEquipment.province=title;
														addEquipment.city=null;
														addEquipment.district=null;

														this.setState({
															addEquipment:addEquipment,
															Equipment_city:valueData.city,
															Equipment_distric:[]
														});
													}}
											/>
										</div>
									</div>
								</div>
								<div style={{width:'12%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'15%',paddingTop:'4px',float:'left'}}>
											<span>市:</span>
										</div>
										<div style={{width:'80%',float:'left'}}>
											<Select
												disabled
												showSearch
												style={{width:'100%'}}
												value={this.state?.data?.city}
												placeholder={'请选择市'}
												options={this.state.Equipment_city || areaData[17].city}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.city=title;
													addEquipment.district=null;

													this.state.Equipment_district=valueData.area;
													this.setState({addEquipment:addEquipment});
												}}
											/>
										</div>
									</div>
								</div>
								<div style={{width:'13%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'15%',paddingTop:'4px',float:'left'}}>
											<span>区:</span>
										</div>
										<div style={{width:'78%',float:'left'}}>
											<Select
												disabled
												showSearch
												style={{width:'100%'}}
												value={this.state?.data?.district}
												placeholder={'请选择市'}
												onChange={(title)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.district=title;
													this.setState({addEquipment:addEquipment});
												}}
											>
												{(this.state?.Equipment_district || areaData[17].city[0].area)?.map((name)=>
													<Option key={name} label={name}>{name}</Option>
												)}
											</Select>
										</div>
									</div>
								</div>
								<div style={{width:'30%',float:'left'}}>
									<div style={{width:'100%'}}>
										<div style={{width:'15%',paddingTop:'4px',float:'left'}}>
											<span>详细地址:</span>
										</div>
										<div style={{width:'80%',float:'left'}}>
											<Input
												disabled
												style={{width:'100%'}}
												// status={this.state?.juder_2_1}
												value={this.state?.data?.details}
												placeholder={'请输入详细地址'}
												onChange={(title)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.details=title.target.value;
													this.setState({addEquipment:addEquipment,juder_2_1:null});
												}}
											/>
										</div>
									</div>
								</div>
								<div style={{width:'15%',float:'left'}}>
									<div style={{width:'30%',float:'left',marginTop:'3px'}}>
										<span>仪器数量：</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<Input value={this.state?.data?.pageInfo?.total} />
									</div>
								</div>
							</div>

							<div style={{width:'100%',height:'50px'}}>
								<div style={{width:'25%',float:'left'}}>
									<div style={{width:'18%',float:'left',marginTop:'3px'}}>
										<span>业务员：</span>
									</div>
									<div style={{width:'70%',float:'left'}}>
										<Select
											style={{width:'100%'}}
											// mode="multiple"
											// status={this.state?.salesmanStatus}
											allowClear
											disabled={this.state?.juder_1}
											value={this.state?.data?.salesman}
											onChange={(text)=>{
												var data = this.state?.data;
												if(text){
													data.salesman=text;
												}else{
													data.salesman='';
												}
												this.setState({data:data});
											}}
										>
											<Option key={'韦杰'} label={'韦杰'}>韦杰</Option>
											<Option key={'林迪启'} label={'林迪启'}>林迪启</Option>
											<Option key={'林启迪'} label={'蒋宁湘'}>蒋宁湘</Option>
											<Option key={'熊攻科'} label={'熊攻科'}>熊攻科</Option>
											<Option key={'杨能'} label={'杨能'}>杨能</Option>
											<Option key={'程立国'} label={'程立国'}>程立国</Option>
											<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
											<Option key={'李梦婷'} label={'李梦婷'}>李梦婷</Option>
											<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
											<Option key={'李进'} label={'李进'}>李进</Option>
											<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
											<Option key={'田辉'} label={'田辉'}>田辉</Option>
											<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
											<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
											<Option key={'马琼'} label={'马琼'}>马琼</Option>
											<Option key={'杨超'} label={'杨超'}>杨超</Option>
											<Option key={'其他'} label={'其他'}>其他</Option>
										</Select>
									</div>
								</div>
								<div style={{width:'30%',float:'left'}}>
									<div style={{width:'13%',float:'left',marginTop:'3px'}}>
										<span>检定员：</span>
									</div>
									<div style={{width:'75%',float:'left'}}>
										<Select
												style={{width:'100%'}}
												// status={this.state?.verifierStatus}
												mode="multiple"
												allowClear
												disabled={this.state?.juder_1}
												// maxTagCount={2}
												value={this.state.data?.verifier||[]}

												onChange={(text)=>{
													var data = this.state?.data;
													// if(text.length !== 0){
													// 	this.setState({verifierStatus:null})
													// }else{
													// 	this.setState({verifierStatus:'error'})
													// }
													if(text?.length>3){
														return message.info('仅限三位');
													}
													data.verifier=text;
													this.setState({data:data});
											}}
										>
											<Option key={'陈佳伟'} label={'陈佳伟'}>陈佳伟</Option>
											<Option key={'杨超'} label={'杨超'}>杨超</Option>
											<Option key={'马琼'} label={'马琼'}>马琼</Option>
											<Option key={'聂立功'} label={'聂立功'}>聂立功</Option>
											<Option key={'曾思红'} label={'曾思红'}>曾思红</Option>
											<Option key={'田辉'} label={'田辉'}>田辉</Option>
											<Option key={'杨能'} label={'杨能'}>杨能</Option>
											<Option key={'程立国'} label={'程立国'}>程立国</Option>
											<Option key={'盛浩'} label={'盛浩'}>盛浩</Option>
											<Option key={'江军雄'} label={'江军雄'}>江军雄</Option>
											<Option key={'李进'} label={'李进'}>李进</Option>
										</Select>
									</div>
								</div>
							</div>
						</div>


						{/*<FormsTwo*/}
						{/*	ref={e => this.forms = e}*/}
						{/*	formParamenter={this.state.formParamenter}*/}
						{/*	data={this.state.data}*/}
						{/*/>*/}
					</div>
					<div className={'topTitleBox'} style={{padding:'0 0 5px 0',display:this.state.showVal}}>
						<span className={'addLink'} onClick={() =>
							this.addQrcodeStatus(true)}>
							<PlusSquareOutlined />新增检定报警器
						</span>
					</div>
					<div style={{clear:'both',padding:'0 10px'}}>
						<TableComponents
						paramenter={this.state.tableParamenter}
						setData = {getVerificationRecordById}
						setTableValue={this.setTableValue}
						ref={e => this.table = e}
					>
						</TableComponents>
					</div>
					{/*-----------------------------------------------------*/}
					<Modal
                        title="添加检定设备"
                        style={{ top: 0 }}
                        visible={this.state.devVisible}
                        okText="确定"
                        onOk={() => this.addGasAlarmData(true)}           // 点击确定
                        onCancel={() => this.addQrcodeStatus(false)}       // 点击取消
                        width={900}
                        centered
                    >
                        <div>
							<FormsTwo
							ref={e => this.forms = e}
							formParamenter={this.state.ModalFormParamenter}
							data={this.state.data}
							/>
                        </div>
                    </Modal>

					<Modal
                        title='作废'
                        style={{ top: 0 }}
                        visible={this.state.voidVisible}
                        okText="确定"
                        onOk={() => this.toVoid(false)}           // 点击确定
                        onCancel={() => this.toVoidStatus(false)}       // 点击取消
                        width={400}
                        centered
                    >
                        <div style={{lineHeight:'60px',padding:'0 10px',textAlign:'center'}}>
							<h4>确认作废该检定记录吗？</h4>
                        </div>
                    </Modal>
					<Modal
                        title='下载'
                        style={{ top: 0 }}
                        visible={this.state.downloadVisible}
                        okText="确定"
                        onOk={() => this.downloadStatus(false)}           // 点击确定
                        onCancel={() => this.downloadStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.downloadOpen(this.state.downloadId,0,1,0)}>记录</span>
							<span onClick={() => this.downloadOpenNull(this.state.downloadId,0,1,1)}>模板</span>
                        </div>
                    </Modal>
					<Modal
                        title='预览'
                        style={{ top: 0 }}
                        visible={this.state.previewVisible}
                        okText="确定"
                        onOk={() => this.previewStatus(false)}           // 点击确定
                        onCancel={() => this.previewStatus(false)}       // 点击取消
                        width={500}
                        centered
                    >
                        <div className={'downloadLink'} style={{padding:'0 10px',textAlign:'center'}}>
							<span onClick={() => this.previewOpen(this.state.previewId,0,1,0)}>记录</span>
							<span onClick={() => this.previewOpenNull(this.state.previewId,0,1,1)}>模板</span>
                        </div>
                    </Modal>




					{/*-----------------------------------------新---添加检定设备-------------------------------------*/}
					<Modal
						maskClosable={false}
						title="添加检定设备"
						style={{ top: 0 }}
						visible={this.state.variableVisible_2}
						okText="确定"
						onOk={() => {
							this.addGasAlarmData(true)
						}}// 点击确定
						onCancel={() => this.setState({variableVisible_2:false})}// 点击取消
						width={900}
						centered
					>
						<div style={{clear:'both',height:'180px',padding:'0 10px',whiteSpace: 'nowrap'}}>
							<div style={{width:'100%',height:(this.state.juder_3_1||this.state.juder_3_2)?'50px':'40px'}}>

								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
										<span style={{color:'red'}}>*</span>
										<span>设备名称:</span>
									</div>
									<div style={{width:'70%'}}>
										<Select
											showSearch
											status={this.state?.juder_3_1}
											style={{width:'100%'}}
											value={this.state.addEquipment.equipmentName}
											placeholder={'请选择设备名称'}
											options={this.state.select_equipmentName}
											fieldNames={ {label: 'value', value: 'key', children: 'children'} }
											onChange={(title,valueData)=>{

												var addEquipment = this.state.addEquipment;
												addEquipment.equipmentName=valueData.value;
												addEquipment.model=null;
												this.equipmentIdSelect(title,valueData);
												this.setState({addEquipment:addEquipment,juder_3_1:''})
											}}
										/>
									</div>
									<div style={{width:'100%',
										margin:'-3px 0 0 20%',display:this.state?.juder_3_1?null:'none'
										// position:'absolute',top:100
									}}>
										<span style={{color:'red'}}>请选择设备名称</span>
									</div>
								</div>

								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
										<span style={{color:'red'}}>*</span>
										<span>型号规格:</span>
									</div>
									<div style={{width:'70%'}}>
										<AutoComplete
											disabled={this.state.model_juder}
											loading={this.state.model_juder}
											showSearch
											style={{width:'100%'}}
											status={this.state?.juder_3_2}
											value={this.state.addEquipment.model}
											placeholder={'请选择型号规格'}
											options={this.state.select_model}
											fieldNames={ {label: 'devModelName', value: 'devModelId', children: 'children'} }
											filterOption={(inputValue, option) => option?.devModelName?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1}

											onChange={(title,valueData,data)=>{

												var addEquipment = this.state.addEquipment;
												if(valueData?.devModelName){
													addEquipment.model=valueData.devModelName;
													addEquipment.manufacturers=valueData.manufacture;
												}else{
													addEquipment.model=title;
													// addEquipment.manufacturers=null;
												}
												this.setState({addEquipment:addEquipment,juder_3_2:''})
											}}
										/>
									</div>
									<div style={{width:'100%',
										margin:'-3px 0 0 20%',display:this.state?.juder_3_2?null:'none'
										// position:'absolute',top:100
									}}>
										<span style={{color:'red'}}>请选择型号规格</span>
									</div>
								</div>

							</div>


							<div style={{width:'100%',height:(this.state.juder_3_3)?'50px':'40px'}}>

								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
										<span style={{color:'red'}}>*</span>
										<span>出厂编号:</span>
									</div>
									<div style={{width:'70%'}}>
										<Input
											style={{width:'100%'}}

											status={this.state?.juder_3_3}
											value={this.state.addEquipment.startNumber}
											placeholder={'请输入出厂编号'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.startNumber=title.target.value;
												this.setState({addEquipment:addEquipment,juder_3_3:null});
											}}
										/>
									</div>
									<div style={{width:'100%',
										margin:'-3px 0 0 20%',display:this.state?.juder_3_3?null:'none'
										// position:'absolute',top:100
									}}>
										<span style={{color:'red'}}>请选择出厂编号</span>
									</div>
								</div>

								<div style={{width:'40%',height:'100%',float:'left'}}>
									<div style={{width:'20%',paddingTop:'4px',float:'left'}}>
										<span>制造厂家:</span>
									</div>
									<div style={{width:'70%'}}>
										<Input
											style={{width:'100%'}}
											// status={this.state?.juder_2_1}
											value={this.state.addEquipment.manufacturers}
											placeholder={'请输入公司单位'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.manufacturers=title.target.value;
												this.setState({addEquipment:addEquipment,juder_2_1:null});
											}}
										/>
									</div>
								</div>

							</div>

							{/*<div style={{width:'100%',height:'40px',float:'left'}}>*/}
							{/*	<div style={{width:'100%',height:'60%'}}>*/}
							{/*		<div style={{width:'8%',height:'100%',paddingTop:'4px',float:'left'}}>*/}
							{/*			<span>安装位置:</span>*/}
							{/*		</div>*/}
							{/*		<div style={{width:'40%',height:'100%',float:'left'}}>*/}
							{/*			<Input*/}
							{/*				style={{width:'100%'}}*/}
							{/*				// status={this.state?.juder_2_1}*/}
							{/*				value={this.state.addEquipment.location}*/}
							{/*				placeholder={'请输入安装位置'}*/}
							{/*				onChange={(title)=>{*/}
							{/*					var addEquipment = this.state.addEquipment;*/}
							{/*					addEquipment.location=title.target.value;*/}
							{/*					this.setState({addEquipment:addEquipment,juder_2_1:null});*/}
							{/*				}}*/}
							{/*			/>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}


							<div style={{width:'23%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'35%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>省:</span>
									</div>
									<div style={{width:'60%',height:'100%',float:'left'}}>
										<Select showSearch
												style={{width:'100%'}}
												value={this.state.addEquipment.province}
												placeholder={'请选择省'}
												options={areaData}
												fieldNames={ {label: 'name', value: 'name', children: 'children'} }
												onChange={(title,valueData)=>{
													var addEquipment = this.state.addEquipment;
													addEquipment.province=title;
													addEquipment.city=null;
													addEquipment.district=null;
													this.setState({
														addEquipment:addEquipment,
														Equipment_city:valueData.city,
														Equipment_distric:[]
													});
												}}
										/>
									</div>
								</div>
							</div>
							<div style={{width:'18%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'14%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>市:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Select

											showSearch
											style={{width:'100%'}}
											value={this.state.addEquipment.city}
											placeholder={'请选择市'}
											options={this.state.Equipment_city || areaData[17].city}
											fieldNames={ {label: 'name', value: 'name', children: 'children'} }
											onChange={(title,valueData)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.city=title;
												addEquipment.district=null;

												this.state.Equipment_district=valueData.area;
												this.setState({addEquipment:addEquipment});
											}}
										/>
									</div>
								</div>
							</div>
							<div style={{width:'18%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'14%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>区:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Select
											showSearch
											style={{width:'100%'}}
											value={this.state.addEquipment.district}
											placeholder={'请选择市'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.district=title;
												this.setState({addEquipment:addEquipment});
											}}
										>
											{(this.state?.Equipment_district || areaData[17].city[0].area)?.map((name)=>
												<Option key={name} label={name}>{name}</Option>
											)}
										</Select>
									</div>
								</div>
							</div>
							<div style={{width:'40%',height:'40px',float:'left'}}>
								<div style={{width:'100%',height:'60%'}}>
									<div style={{width:'20%',height:'100%',paddingTop:'4px',float:'left'}}>
										<span>详细地址:</span>
									</div>
									<div style={{width:'80%',height:'100%',float:'left'}}>
										<Input
											style={{width:'100%'}}
											// status={this.state?.juder_2_1}
											value={this.state.addEquipment.details}
											placeholder={'请输入详细地址'}
											onChange={(title)=>{
												var addEquipment = this.state.addEquipment;
												addEquipment.details=title.target.value;
												this.setState({addEquipment:addEquipment,juder_2_1:null});
											}}
										/>
									</div>
								</div>
							</div>


							{/*<div style={{width:'100%',height:'40px',float:'left'}}>*/}
							{/*	<div style={{width:'100%',height:'60%'}}>*/}
							{/*		<div style={{width:'8%',height:'100%',paddingTop:'4px',float:'left'}}>*/}
							{/*			<span>检定日期:</span>*/}
							{/*		</div>*/}
							{/*		<div style={{width:'40%',height:'100%',float:'left'}}>*/}
							{/*			<DatePicker*/}
							{/*				style={{width:'60%'}}*/}
							{/*				showTime={{ format: 'HH:mm:ss' }}*/}
							{/*				format={'YYYY-MM-DD HH:mm:ss'}*/}
							{/*				// value={this.state.addEquipment.verifierDate}*/}
							{/*				placeholder={'请输入检定日期'}*/}
							{/*				onChange={(title,v)=>{*/}
							{/*					console.log(v);*/}
							{/*					var addEquipment = this.state.addEquipment;*/}
							{/*					addEquipment.verifierDate=v;*/}
							{/*					this.setState({addEquipment:addEquipment});*/}
							{/*				}}*/}
							{/*			/>*/}
							{/*		</div>*/}
							{/*	</div>*/}
							{/*</div>*/}

						</div>
					</Modal>

				</div>


            </Layout>
        )
    }
}
